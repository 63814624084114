<template>
  <div class="container">
    <h3>Exercises</h3>

    <div class="row">
      <div class="col-12">
        <!-- CREATE SEARCH FUNCTIONAL -->
        <form class="form-inline float-right">
          <input class="form-control" v-model="searchBox" type="text" placeholder="Search...">
        </form>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-12 table-responsive">
        <table class="table table-striped border text-center">
          <thead class="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Code</th>
            <th scope="col">Name</th>
            <th scope="col">Repeats</th>
            <th scope="col">Side</th>
            <th scope="col">Position</th>
            <th scope="col">Product Version</th>
            <th scope="col">Active</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="exercise in filteredExercises" :key="exercise.id">
            <th class="align-middle" scope="row">{{ exercise.id }}</th>
            <td class="align-middle">{{ exercise.code }}</td>
            <td class="align-middle">{{ exercise.name }}</td>
            <td class="align-middle">{{ exercise.repeats }}</td>
            <td class="align-middle">{{ exercise.side }}</td>
            <td class="align-middle">{{ exercise.position }}</td>
            <td class="align-middle">{{ exercise.product_version }}</td>
            <td class="align-middle">
              <div class="custom-control custom-switch">
                <input type="checkbox"
                       :id="'exercise_' + exercise.id"
                       v-model="exercise.active"
                       class="custom-control-input"
                       v-on:change="toggleStatus(exercise)">
                <label class="custom-control-label" :for="'exercise_' + exercise.id"></label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>

    <b-modal id="toggle-status-modal" backdrop="static" :keyboard="false" dialog-style="modal-dialog-scrollable">
      <template v-slot:modal-title>
        <div class="modal-header">
          <div class="d-flex justify-content-center align-items-center modal-title flex-column w-100">
            <h5><b>{{ translations.action_confirmation_required }}</b></h5>
          </div>
        </div>
      </template>
      <div class="d-flex justify-content-center align-items-center w-50 error-color m-auto text-center p-3">
        {{ translations.do_you_want_to_continue }}
      </div>
      <template #modal-footer="{ cancel, hide }">
        <button @click="cancelToggleStatus(cancel)" class="btn btn-danger align-self-center">Cancel</button>
        <button @click="confirmToggleStatus(hide)" class="btn btn-primary align-self-center">Yes</button>
      </template>
    </b-modal>
  </div>
</template>


<script>
import axios from 'axios';
import translations from '../../translations';

export default {
  name: 'Exercises.vue',
  data() {
    return {
      translations: translations.assets_manager,
      exercisesList: [],
      searchBox: '',
      toggleExStatus: null,
    };
  },
  methods: {
    cancelToggleStatus(modalCancel) {
      this.toggleExStatus.active = !this.toggleExStatus.active;
      modalCancel();
    },

    confirmToggleStatus(modalHide) {
      axios.put(`v1/therapies/exercises/${this.toggleExStatus.id}`, { active: this.toggleExStatus.active })
        .then(response => {
          if (response.data.exercise && response.data.exercise.active === this.toggleExStatus.active) {
            this.toggleExStatus = null;
            modalHide();
          }
        }).catch(e => {
          console.error('unable to change exercise status', e);
        });
    },

    toggleStatus(exercise) {
      this.toggleExStatus = exercise;
      this.$bvModal.show('toggle-status-modal');
    },

    fetchExercisesList() {
      const that = this;
      axios.get('v1/therapies/exercises').then(response => {
        that.exercisesList = response.data.exercises;
      });
    },
  },

  created() {
    this.fetchExercisesList();
  },

  computed: {
    filteredExercises() {
      const searchFields = [ 'id', 'code', 'name', 'repeats', 'side', 'position' ];
      const searchFilter = exercise => searchFields.some(field => {
        const fieldValue = String(exercise[field]).toLowerCase();
        return fieldValue.includes(String(this.searchBox).toLowerCase());
      });

      return this.exercisesList.filter(searchFilter);
    },
  },
};
</script>
